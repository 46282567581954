<template>
  <div>
    <PageTopSection title="Afridext Aml" />
    <section class="blog-details">
        <div class="container">
          <div class="row">
            <div class="col-lg">
              <div class="blog-details__content">
                <!-- /image -->
                <p class="MsoNormal" style="text-align: center; line-height: 115%;" align="center">
                  <strong style="mso-bidi-font-weight: normal;">
                    <span style="font-size: 14.0pt; line-height: 115%;">AFRIDEXT INTEGRATED SERVICES LLC ANTI-MONEY LAUNDERING POLICY</span>
                  </strong>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                  <u>Content</u>
                </p>
                
                <div class="aml-content cleafix">
                  <p><span>1.	Company’s Policy</span>
                    <span>2</span></p>
                  <p><span>2.	AML Compliance Person Designation and Duties</span>
                    <span>3</span></p>
                  <p><span>3.	Giving AML Information to Federal Law Enforcement Agencies and Other Financial Institutions</span>
                    <span>4</span></p>
                  <p><span>4.	Checking the Office of Foreign Assets Control Listings</span>
                    <span>8</span></p>
                  <p><span>5.	Customer Identification Program</span>
                    <span>9</span></p>
                  <p><span>6.	Customer Due Diligence Rule</span>
                    <span>15</span></p>
                  <p><span>7.	Correspondent Accounts for Foreign Shell Banks</span>
                    <span>17</span></p>
                  <p><span>8.	Due Diligence and Enhanced Due Diligence Requirements for Correspondent Accounts of Foreign Financial Institutions</span>
                    <span>19</span></p>
                  <p><span>9.	Due Diligence and Enhanced Due Diligence Requirements for Private Banking Accounts/Senior Foreign Political Figures</span>
                    <span>23</span></p>
                  <p><span>10. Compliance with FinCEN’s Issuance of Special Measures Against Foreign Jurisdictions, Financial Institutions or International Transactions of Primary Money Laundering Concern</span>
                    <span>23</span></p>
                  <p><span>11. Monitoring Accounts for Suspicious Activity</span>
                    <span>25</span></p>
                  <p><span>12. Suspicious Transactions and BSA Reporting</span>
                    <span>37</span></p>
                  <p><span>13. AML Recordkeeping</span>
                    <span>42</span></p>
                  <p><span>14. Clearing/Introducing Company Relationships</span>
                    <span>44</span></p>
                  <p><span>15. Training Programs</span>
                    <span>45</span></p>
                  <p><span>16. Program to Independently Test AML Program</span>
                    <span>45</span></p>
                  <p><span>17. Monitoring Employee Conduct and Accounts</span>
                    <span>46</span></p>
                  <p><span>18. Confidential Reporting of AML Non-Compliance</span>
                    <span>47</span></p>
                  <p><span>19. Additional Risk Areas</span>
                    <span>47</span></p>
                  <p><span>20. Senior Management Approval</span>
                    <span>47</span></p>
                </div>

                <p style="line-height: 150%;">
                  <em>&nbsp;</em>
                </p>

                <h2 style="line-height: 150%;">
                  <span style="font-size: 12.0pt; line-height: 150%;">Company’s Policy</span>
                </h2>

                <p class="MsoNormal" style="line-height: 150%;">It is the
                    policy of the company to prohibit and actively prevent money laundering and any
                    activity that facilitates money laundering or the funding of terrorist or
                    criminal activities by complying with all applicable requirements under the Bank
                    Secrecy Act (BSA) and its implementing regulations.</p>
                <p class="MsoNormal" style="line-height: 150%;">Money
                    laundering is generally defined as engaging in acts designed to conceal or
                    disguise the true origins of criminally derived proceeds so that the proceeds
                    appear to have derived from legitimate origins or constitute legitimate assets.
                    <span style="color: black;">Generally, money laundering occurs in three stages.
                        Cash first enters the financial system at the "placement" stage, where the cash
                        generated from criminal activities is converted into monetary instruments, such
                        as money orders or traveler's checks, or deposited into accounts at financial
                        institutions. At the "layering" stage, the funds are transferred or moved into
                        other accounts or other financial institutions to further separate the money
                        from its criminal origin. At the "integration" stage, the funds are reintroduced
                        into the economy and used to purchase legitimate assets or to fund other
                        criminal activities or legitimate businesses.<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="color: black;">Although cash is rarely deposited into securities
                        accounts, the securities industry is unique in that it can be used to launder
                        funds obtained elsewhere, and to generate illicit funds within the industry
                        itself through fraudulent activities. Examples of types of fraudulent activities
                        include insider trading, market manipulation, ponzi schemes, cybercrime and
                        other investment-related fraudulent activity.</span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="color: black;">Terrorist financing may not involve the proceeds of
                        criminal conduct, but rather an attempt to conceal either the origin of the
                        funds or their intended use, which could be for criminal purposes. Legitimate
                        sources of funds are a key difference between terrorist financiers and
                        traditional criminal organizations. In addition to charitable donations,
                        legitimate sources include foreign government sponsors, business ownership and
                        personal employment. Although the motivation differs between traditional money
                        launderers and terrorist financiers, the actual methods used to fund terrorist
                        operations can be the same as or similar to methods used by other criminals to
                        launder funds. Funding for terrorist attacks does not always require large sums
                        of money and the associated transactions may not be complex.</span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="color: black;">Our AML policies, procedures and internal controls
                        are designed to ensure compliance with all applicable BSA regulations and FINRA
                        rules and will be reviewed and updated on a regular basis to ensure appropriate
                        policies, procedures and internal controls are in place to account for both
                        changes in regulations and changes in our business.</span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    
                </p>

                <h2 style="line-height: 150%;">
                  <span style="font-size: 12.0pt; line-height: 150%;">2. AML Compliance Person Designation and Duties</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">The company
                    has designated Oyebayo Damilola as its Anti-Money Laundering Program Compliance
                    Person (AML Compliance Person), with full responsibility for the company’s
                    AML program. Oyebayo Damilola has a working knowledge of the BSA (Bank Security
                    Act) and its implementing regulations and is qualified by experience, knowledge
                    and training. The duties of the AML Compliance Person will include monitoring
                    the company’s compliance with AML obligations, overseeing communication
                    and training for employees. The AML Compliance Person will also ensure that the
                    company keeps and maintains all of the required AML records and will ensure that
                    Suspicious Activity Reports (SARs) are filed with the Financial Crimes
                    Enforcement Network (FinCEN) when appropriate. The AML Compliance Person is
                    vested with full responsibility and authority to enforce the company’s AML
                    program.<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">The company will provide FINRA with
                        contact information for the AML Compliance Person through the FINRA Contact
                        System (FCS), including: (1) name; (2) title; (3) mailing address; (4) email
                        address; (5) telephone number; and (6) facsimile (if any). The company will
                        promptly notify FINRA of any change in this information through FCS and will
                        review, and if necessary update, this information within 17 business days after
                        the end of each calendar year. The annual review of FCS information will be
                        conducted by
                    </span>Oyebayo Damilola<span style="mso-bidi-font-style: italic;">
                        and will be completed with all necessary updates being provided no later than 17
                        business days following the end of each calendar year. In addition, if there is
                        any change to the information,
                    </span>Oyebayo Damilola<span style="mso-bidi-font-style: italic;">
                        will update the information promptly, but in any event not later than 30 days
                        following the change.
                    </span>
                </p>
                
                <h2 style="line-height: 150%;">
                  <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">3. Giving AML Information to Federal Law Enforcement Agencies and OtherFinancial Institutions</span>
                </h2>
                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                  <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">a. FinCEN Requests Under USA PATRIOT Act Section 314(a)</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will respond to a Financial Crimes
                        Enforcement Network (FinCEN) request concerning accounts and transactions by
                        immediately searching our records to determine whether we maintain or have
                        maintained any account for, or have engaged in any transaction with, each
                        individual, entity or organization named in the 314(a) Request as outlined in
                        the Frequently Asked Questions (FAQ) located on FinCEN’s secure website.
                        We understand that we have 14 days (unless otherwise specified by FinCEN) from
                        the transmission date of the request to respond to a 314(a) Request. We will
                        designate through the FINRA Contact System (FCS) one or more persons to be the
                        point of contact (POC) for 314(a) Requests and will promptly update the POC
                        information following any change in such information. Unless otherwise stated in
                        the 314(a) Request or specified by FinCEN, we are required to search those
                        documents outlined in FinCEN’s FAQ<a name="OLE_LINK2"></a>
                        <a name="OLE_LINK1"></a>
                        <span style="mso-bookmark: OLE_LINK2;">. If we find a match,
                        </span>
                    </span>
                    <span style="mso-bookmark: OLE_LINK1;">
                        <span style="mso-bookmark: OLE_LINK2;">Oyebayo Damilola<span style="mso-bidi-font-weight: bold;">
                                will report it to FinCEN via FinCEN’s Web-based 314(a) Secure Information
                                Sharing System within 14 days or within the time requested by FinCEN in the
                                request. If the search parameters differ from those mentioned above (for
                                example, if FinCEN limits the search to a geographic location),
                            </span>Oyebayo Damilola<span style="mso-bidi-font-weight: bold;">
                                will structure our search accordingly.</span>
                        </span>
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">If
                    </span>Oyebayo Damilola<span style="mso-bidi-font-weight: bold;">
                        searches our records and does not find a matching account or transaction, then
                    </span>Oyebayo Damilola<span style="mso-bidi-font-weight: bold;">
                        will not reply to the 314(a) Request. We will maintain documentation that we
                        have performed the required search by<em style="mso-bidi-font-style: normal;"></em>maintaininga log showing the date of the request, the number of accounts searched, the nameof the individual conducting the search and a notation of whether or not a matchwas found.</span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will not disclose the fact that
                        FinCEN has requested or obtained information from us, except to the extent
                        necessary to comply with the information request.
                    </span>Oyebayo Damilola<span style="mso-bidi-font-weight: bold;">
                        will review, maintain and implement procedures to protect the security and
                        confidentiality of requests from FinCEN similar to those procedures established
                        to satisfy the requirements of Section 501 of the Gramm-Leach-Bliley Act with
                        regard to the protection of customers’ nonpublic information.<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will direct any questions we have
                        about the 314(a) Request to the requesting federal law enforcement agency as
                        designated in the request.<span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">Unless otherwise stated in the 314(a)
                        Request, we will not be required to treat the information request as continuing
                        in nature, and we will not be required to treat the periodic 314(a) Requests as
                        a government provided list of suspected terrorists for purposes of the customer
                        identification and verification requirements.
                    </span>
                </p>

                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                  <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">b. National Security Letters</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We understand that the receipt of a
                        National Security Letter (NSL) is highly confidential. We understand that none
                        of our officers, employees or agents may directly or indirectly disclose to any
                        person that the FBI or other federal government authority has sought or obtained
                        access to any of our records. To maintain the confidentiality of any NSL we
                        receive, we will process and maintain the NSL by identifying individuals whose
                        support may be necessary to collect the information and identify outside counsel
                        who can assist and provide advice on obligations that may accompany the receipt
                        of an NSL. If we file a SAR after receiving an NSL, the SAR will not contain any
                        reference to the receipt or existence of the NSL. The SAR will only contain
                    </span>detailed information about the facts and circumstances of the detected
                    suspicious activity.<span style="mso-bidi-font-style: italic;">
                        <span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </p>

                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                  <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">c. Grand Jury Subpoenas</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We understand that the receipt of a
                        grand jury subpoena concerning a customer does not in itself require that we
                        file a Suspicious Activity Report (SAR). When we receive a grand jury subpoena,
                        we will conduct a risk assessment of the customer subject to the subpoena as
                        well as review the customer’s account activity. If we uncover suspicious
                        activity during our risk assessment and review, we will elevate that
                        customer’s risk assessment and file a SAR in accordance with the SAR
                        filing requirements. We understand that none of our officers, employees or
                        agents may directly or indirectly disclose to the person who is the subject of
                        the subpoena its existence, its contents or the information we used to respond
                        to it. To maintain the confidentiality of any grand jury subpoena we receive, we
                        will process and maintain the subpoena by not disclosing its content. If we file
                        a SAR after receiving a grand jury subpoena, the SAR will not contain any
                        reference to the receipt or existence of the subpoena. The SAR will only contain
                    </span>detailed information about the facts and circumstances of the detected
                    suspicious activity.</p>
                
                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                  <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">d. Voluntary Information Sharing With Other Financial Institutions Under USAPATRIOT Act Section 314(b)</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will share information with other
                        financial institutions regarding individuals, entities, organizations and
                        countries for purposes of identifying and, where appropriate, reporting
                        activities that we suspect may involve possible terrorist activity or money
                        laundering.
                    </span>Oyebayo Damilola<span style="mso-bidi-font-weight: bold;">
                        will ensure that the firm files with FinCEN an initial notice before any sharing
                        occurs and annual notices thereafter. We will use the notice form found at
                        <span class="MsoHyperlink">
                            <span style="color: black; mso-themecolor: text1; text-decoration: none; text-underline: none;">
                                <a href="http://www.fincen.gov/">
                                    <span style="color: black; mso-themecolor: text1;">FinCEN’s</span>
                                    <span style="color: black; mso-themecolor: text1;">website</span>
                                </a>
                            </span>
                        </span>. Before we share information with another financial institution, we will
                        take reasonable steps to verify that the other financial institution has
                        submitted the requisite notice to FinCEN, either by obtaining confirmation from
                        the financial institution or by consulting a list of such financial institutions
                        that FinCEN will make available. We understand that this requirement applies
                        even to financial institutions<em>
                            with which we are affiliated</em>, and that we will obtain the requisite noticesfrom affiliates and follow all required procedures<em>.</em>
                        <span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will employ strict procedures both
                        to ensure that only relevant information is shared and to protect the security
                        and confidentiality of this information, for example, by segregating it from the
                        company’s other books and records.
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We also will employ procedures to
                        ensure that any information received from another financial institution shall
                        not be used for any purpose other than:</span>
                </p>
                <p>
                  </p><ul class="text-dark list-unstyled">
                    <li>
                      identifying and, where appropriate, reporting on money laundering or terrorist activities;
                    </li>
                    <li>
                      determining whether to establish or maintain an account, or to engage in a transaction; o
                    </li>
                    <li>
                      assisting the financial institution in complying with performing such activities.
                    </li>
                    <li>
                      assisting the financial institution in complying with performing such activities.
                    </li>
                  </ul>
                <p></p>
                
                <h2 style="line-height: 150%;">
                  <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">e. Joint Filing of SARs by Broker-Dealers and Other Financial Institutions</span>
                </h2>
                
                <p class="MsoNormal" style="line-height: 150%;">We will
                    file joint SARs to identify financial links to illicit activities. We will also
                    share information about a particular suspicious transaction with any
                    broker-dealer, as appropriate, involved in that particular transaction for
                    purposes of determining whether we will file jointly a SAR.</p>
                
                <p class="MsoNormal" style="line-height: 150%;">We will
                    share information about particular suspicious transactions with our clearing
                    broker for purposes of determining whether we and our clearing broker will file
                    jointly a SAR. In cases in which we file a joint SAR for a transaction that has
                    been handled both by us and by the clearing broker, we may share with the
                    clearing broker a copy of the filed SAR.<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">If we
                    determine it is appropriate to jointly file a SAR, we understand that we cannot
                    disclose that we have filed a SAR to any financial institution except the
                    financial institution that is filing jointly. If we determine it is not
                    appropriate to file jointly (<em style="mso-bidi-font-style: normal;">e.g.</em>,because the SAR concerns the other broker-dealer or one of its employees), weunderstand that we cannot disclose that we have filed a SAR to any otherfinancial institution or insurance company.</p>
                <p class="MsoNormal" style="line-height: 150%;">
                    
                </p>
                
                <h2 style="line-height: 150%;">
                  <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">4. Checking the Office of Foreign Assets Control Listings</span>
                </h2>

                <p class="MsoNormal" style="line-height: 150%;">Before
                    opening an account, and on an ongoing basis, Oyebayo Damilola will check to
                    ensure that a customer does not appear on the SDN list or is not engaging in
                    transactions that are prohibited by the economic sanctions and embargoes
                    administered and enforced by OFAC. Because the SDN list and listings of economic
                    sanctions and embargoes are updated frequently, we will consult them on a
                    regular basis and subscribe to receive any available updates when they occur.
                    With respect to the SDN list, we may also access that list through various
                    software programs to ensure speed and accuracy. Oyebayo Damilola will also
                    review existing accounts against the SDN list and listings of current sanctions
                    and embargoes when they are updated and he will document the review.</p>
                
                <p class="MsoNormal" style="line-height: 150%;">If we
                    determine that a customer is on the SDN list or is engaging in transactions that
                    are prohibited by the economic sanctions and embargoes administered and enforced
                    by OFAC, we will reject the transaction and/or block the customer's assets and
                    file a blocked assets and/or rejected transaction form with OFAC within 10 days.
                    We will also call the OFAC Hotline immediately.
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">Our review will include customer
                        accounts, transactions involving customers (including activity that passes
                        through the firm such as wires) and the review of customer transactions that
                        involve physical security certificates or application-based investments (<em style="mso-bidi-font-style: normal;">e.g.</em>, mutual funds).</span>
                </p>
                
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">5. Customer Identification Program</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">In addition to the information we
                        must collect under FINRA Rules 2090 (Know Your Customer) and 2111 (Suitability)
                        and the 4510 Series (Books and Records Requirements), and Securities Exchange
                        Act of 1934 (Exchange Act) Rules 17a-3(a)(9) (Beneficial Ownership regarding
                        Cash and Margin Accounts), 17a-3(a)(17) (Customer Accounts) and Regulation Best
                        Interest, we have established, documented and maintained a written Customer
                        Identification Program (CIP). We will collect certain minimum customer
                        identification information from each customer who opens an account; utilize
                        risk-based measures to verify the identity of each customer who opens an
                        account; record customer identification information and the verification methods
                        and results; provide the required adequate CIP notice to customers that we will
                        seek identification information to verify their identities; and compare customer
                        identification information with government-provided lists of suspected
                        terrorists, once such lists have been issued by the government.</span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">We will
                    collect information to determine whether any entity opening an account would be
                    excluded as a “customer,” pursuant to the exceptions outlined in 31
                    CFR 1023.100(d)(2)) (<em style="mso-bidi-font-style: normal;">e.g.</em>,documentation of a company’s listing information, licensing orregistration of a financial institution in the U.S., and status or verificationof the authenticity of a government agency or department).</p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">a. Required Customer Information</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">Prior</span>
                    to opening an account, the company will collect the following information for
                    all accounts, if applicable, for any person, entity or organization that is
                    opening a new account and whose name is on the account:<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(1) the name;</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(2) date of birth (for an individual);</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(3)
                    an address, which will be a residential or business street address (for an
                    individual), an Army Post Office (APO) or Fleet Post Office (FPO) box number, or
                    residential or business street address of next of kin or another contact
                    individual (for an individual who does not have a residential or business street
                    address), or a principal place of business, local office, or other physical
                    location (for a person other than an individual); and</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(4)
                    an identification number, which will be a taxpayer identification number (for
                    U.S. persons), or one or more of the following: a taxpayer identification
                    number, passport number and country of issuance, alien identification card
                    number, or number and country of issuance of any other government-issued
                    document evidencing nationality or residence
                    <u>and</u>
                    bearing a photograph or other similar safeguard (for non-U.S. persons).<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">In the
                    event that a customer has applied for, but has not received, a taxpayer
                    identification number, we will endeavour<em></em>toconfirm that the application was filed before the customer opens the account andto obtain the taxpayer identification number within a reasonable period of timeafter the account is opened.<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">When
                    opening an account for a foreign business or enterprise that does not have an
                    identification number, we will request alternative government-issued
                    documentation certifying the existence of the business or enterprise.</p>
                <p class="MsoNormal" style="line-height: 150%;">
                    
                </p>

                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">b. Customers Who Refuse to Provide Information</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">If a
                    potential or existing customer either refuses to provide the information
                    described above when requested, or appears to have intentionally provided
                    misleading information, our company will not open a new account and, after
                    considering the risks involved, consider closing any existing account. In either
                    case, our AML Compliance Person will be notified so that we can determine
                    whether we should report the situation to FinCEN on a SAR.<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>

                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">c. Verifying Information</span>
                </h2>  
                <p class="MsoNormal" style="line-height: 150%;">Based on
                    the risk, and to the extent reasonable and practicable, we will ensure that we
                    have a reasonable belief that we know the true identity of our customers by
                    using risk-based procedures to verify and document the accuracy of the
                    information we get about our customers. Oyebayo Damilola will analyze the
                    information we obtain to determine whether the information is sufficient to form
                    a reasonable belief that we know the true identity of the customer (<em style="mso-bidi-font-style: normal;">e.g.</em>, whether the information is logical or contains inconsistencies).<span style="mso-spacerun: yes;">&nbsp;&nbsp;
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">We will
                    verify customer identity through documentary means, non-documentary means or
                    both. We will use documents to verify customer identity when appropriate
                    documents are available. In light of the increased instances of identity fraud,
                    we will supplement the use of documentary evidence by using the non-documentary
                    means described below whenever necessary. We may also use non-documentary means,
                    if we are still uncertain about whether we know the true identity of the
                    customer. In verifying the information, we will consider whether the identifying
                    information that we receive, such as the customer’s name, street address,
                    zip code, telephone number (if provided), date of birth and Social Security
                    number, allow us to determine that we have a reasonable belief that we know the
                    true identity of the customer (<em style="mso-bidi-font-style: normal;">e.g.</em>, whether the information is logical or contains inconsistencies).</p>
                
                <p class="MsoNormal" style="line-height: 150%;">Appropriate documents for verifying the identity of customers include the following:</p>
                
                <ul style="margin-top: 0in;" type="disc">
                    <li class="MsoNormals" style="">For
                        an individual, an unexpired government-issued identification evidencing
                        nationality or residence and bearing a photograph or similar safeguard, such as
                        a driver’s license or passport; and</li>
                </ul>
                
                <ul style="margin-top: 0in;" type="disc">
                    <li class="MsoNormal" style="">For
                        a person other than an individual, documents showing the existence of the
                        entity, such as certified articles of incorporation, a government-issued
                        business license, a partnership agreement or a trust instrument.</li>
                </ul>
                
                <p class="MsoNormal" style="line-height: 150%;">We
                    understand that we are not required to take steps to determine whether the
                    document that the customer has provided to us for identity verification has been
                    validly issued and that we may rely on a government-issued identification as
                    verification of a customer’s identity. If, however, we note that the
                    document shows some obvious form of fraud, we must consider that factor in
                    determining whether we can form a reasonable belief that we know the
                    customer’s true identity.</p>
                
                <p class="MsoNormal" style="line-height: 150%;">We will use the following non-documentary methods of verifying identity:</p>
                
                <ul style="margin-top: 0in;" type="disc">
                    <li class="MsoNormal text-dark" style="line-height: 150%; mso-list: l20 level1 lfo9; tab-stops: list .5in;">Independently
                        verifying the customer’s identity through the comparison of information
                        provided by the customer with information obtained from a consumer reporting
                        agency, public database or other source;</li>
                </ul>

                <ul style="margin-top: 0in;" type="disc">
                    <li class="MsoNormal" style="line-height: 150%; mso-list: l12 level1 lfo6; tab-stops: list .5in;">Checking references with other financial institutions; or</li>
                </ul>
                <ul style="margin-top: 0in;" type="disc">
                    <li class="MsoNormal" style="line-height: 150%; mso-list: l12 level1 lfo6; tab-stops: list .5in;">Obtaining a financial statement.</li>
                </ul>
                
                <p class="MsoNormal" style="line-height: 150%;">We will use non-documentary methods of verification when:</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(1)
                    the customer is unable to present an unexpired government-issued identification
                    document with a photograph or other similar safeguard;</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(2)
                    the company is unfamiliar with the documents the customer presents for
                    identification verification;</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(3) the customer and company do not have face-to-face contact; and</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(4)
                    there are other circumstances that increase the risk that the company will be
                    unable to verify the true identity of the customer through documentary means.<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">We will
                    verify the information within a reasonable time before or after the account is
                    opened. Depending on the nature of the account and requested transactions, we
                    may refuse to complete a transaction before we have verified the information, or
                    in some instances when we need more time, we may, pending verification, restrict
                    the types of transactions or dollar amount of transactions. If we find
                    suspicious information that indicates possible money laundering, terrorist
                    financing activity, or other suspicious activity, we will, after internal
                    consultation with the company's AML Compliance Person, file a SAR in accordance
                    with applicable laws and regulations.</p>
                
                <p class="MsoNormal" style="line-height: 150%;">We
                    recognize that the risk that we may not know the customer’s true identity
                    may be heightened for certain types of accounts, such as an account opened in
                    the name of a corporation, partnership or trust that is created or conducts
                    substantial business in a jurisdiction that has been designated by the U.S. as a
                    primary money laundering jurisdiction, a terrorist concern, or has been
                    designated as a non-cooperative country or territory. We will identify customers
                    that pose a heightened risk of not being properly identified.</p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <strong>d. Lack of Verification</strong>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">When we
                    cannot form a reasonable belief that we know the true identity of a customer, we
                    will do the following: (1) not open an account; (2) impose terms under which a
                    customer may conduct transactions while we attempt to verify the
                    customer’s identity; (3) close an account after attempts to verify a
                    customer’s identity fail; and (4) determine whether it is necessary to
                    file a SAR in accordance with applicable laws and regulations.</p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <strong>e. Recordkeeping</strong>
                </p>
                <p class="MsoBodyText2" style="line-height: 150%;">
                    <span style="font-style: normal; mso-bidi-font-style: italic;">We will document
                        our verification, including all identifying information provided by a customer,
                        the methods used and results of verification, and the resolution of any
                        discrepancies identified in the verification process. We will keep records
                        containing a description of any document that we relied on to verify a
                        customer’s identity, noting the type of document, any identification
                        number contained in the document, the place of issuance, and if any, the date of
                        issuance and expiration date. With respect to non-documentary verification, we
                        will retain documents that describe the methods and the results of any measures
                        we took to verify the identity of a customer. We will also keep records
                        containing a description of the resolution of each substantive discrepancy
                        discovered when verifying the identifying information obtained. We will retain
                        records of all identification information for five years after the account has
                        been closed; we will retain records made about verification of the customer's
                        identity for five years after the record is made.
                    </span>
                </p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">f. Comparison with Government-Provided Lists of Terrorists</span>
                </h2>
                <p class="MsoBodyText2" style="line-height: 150%;">
                    <span style="font-style: normal; mso-bidi-font-style: italic;">At such time as
                        we receive notice that a federal government agency has issued a list of known or
                        suspected terrorists and identified the list as a list for CIP purposes, we
                        will, within a reasonable period of time after an account is opened (or earlier,
                        if required by another federal law or regulation or federal directive issued in
                        connection with an applicable list), determine whether a customer appears on any
                        such list of known or suspected terrorists or terrorist organizations issued by
                        any federal government agency and designated as such by Treasury in consultation
                        with the federal functional regulators. We will follow all federal directives
                        issued in connection with such lists.<span style="mso-spacerun: yes;">&nbsp;&nbsp;&nbsp;
                        </span>
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">We will
                    continue to comply separately with OFAC rules prohibiting transactions with
                    certain foreign countries or their nationals.</p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">g. Notice to Customers</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">We
                    will provide notice to customers that the firm is requesting information from
                    them to verify their identities, as required by federal law. We will use the
                    following method to provide notice to customers: phone calls, texts.</p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">Important Information About Procedures for Opening a New Account</span>
                </h2>
                <p class="MsoBodyTextIndent2" style="line-height: 150%;">To
                    help the government fight the funding of terrorism and money laundering
                    activities, federal law requires all financial institutions to obtain, verify,
                    and record information that identifies each person who opens an account.</p>
                
                
                <h3 class="text-dark text-center p-5">
                    <span>What
                        this means for you: When you open an account, we will ask for your name,
                        address, date of birth and other information that will allow us to identify you.
                        We may also ask to see your driver’s license or other identifying
                        documents.</span>
                </h3>
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">h. Reliance on Another Financial Institution for Identity Verification</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">We may,
                    under the following circumstances, rely on the performance by another financial
                    institution (including an affiliate) of some or all of the elements of our CIP
                    with respect to any customer that is opening an account or has established an
                    account or similar business relationship with the other financial institution to
                    provide or engage in services, dealings or other financial transactions:</p>
                
                <p class="MsoNormal" style="margin-left: 75.0pt; text-indent: -.25in; line-height: 150%; mso-list: l8 level1 lfo4; tab-stops: list 75.0pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->when such reliance is reasonable under the circumstances;</p>
                <p class="MsoNormal" style="margin-left: 75.0pt; text-indent: -.25in; line-height: 150%; mso-list: l8 level1 lfo4; tab-stops: list 75.0pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->when the other financial institution is subject to a rule
                        implementing the anti-money laundering compliance program requirements of 31
                        U.S.C. §&nbsp;5318(h), and is regulated by a federal functional regulator;
                    <u>and</u>
                </p>
                <p class="MsoNormal" style="margin-left: 75.0pt; text-indent: -.25in; line-height: 150%; mso-list: l8 level1 lfo4; tab-stops: list 75.0pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->when the other financial institution has entered into a
                        contract with our firm requiring it to certify annually to us that it has
                        implemented its anti-money laundering program and that it will perform (or its
                        agent will perform) specified requirements of the customer identification
                        program.</p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <em>&nbsp;</em>
                </p>
                <p class="MsoHeader" style="line-height: 150%; tab-stops: .5in;">
                    <strong>6. Customer Due Diligence Rule</strong>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">In addition to the information
                        collected under the written Customer Identification Program, FINRA Rules 2090
                        (Know Your Customer) and 2111 (Suitability) and the 4510 Series (Books and
                        Records Requirements), and Securities Exchange Act of 1934 (Exchange Act) Rules
                        17a-3(a)(9) (Beneficial Ownership regarding Cash and Margin Accounts),
                        17a-3(a)(17) (Customer Accounts) and Regulation Best Interest, we have
                        established, documented and maintained written policies and procedures
                        reasonably designed to identify and verify beneficial owners of legal entity
                        customers and comply with other aspects of the Customer Due Diligence (CDD)
                        Rule. We will collect certain minimum CDD information from beneficial owners of
                        legal entity customers. We will understand the nature and purpose of customer
                        relationships for the purpose of developing a customer risk profile. We will
                        conduct ongoing monitoring to identify and report suspicious transactions, and,
                        on a risk basis, maintain and update customer information.<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </p>
                <p class="MsoHeader" style="line-height: 150%; tab-stops: .5in;">
                    <!--[endif]-->
                    <strong style="mso-bidi-font-weight: normal;">a. Identification and Verification of Beneficial Owners</strong>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">At the time
                    of opening an account for a legal entity customer, the company will identify any
                    individual that is a beneficial owner of the legal entity customer by
                    identifying any individuals who directly or indirectly own 25% or more of the
                    equity interests of the legal entity customer, and any individual with
                    significant responsibility to control, manage, or direct a legal entity
                    customer. The following information will be collected for each beneficial owner:</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(1) the name;</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(2) date of birth (for an individual);</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(3)
                    an address, which will be a residential or business street address (for an
                    individual), or an Army Post Office (APO) or Fleet Post Office (FPO) box number,
                    or residential or business street address of next of kin or another contact
                    individual (for an individual who does not have a residential or business street
                    address); and</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">(4)
                    an identification number, which will be a Social Security number (for U.S.
                    persons), or one or more of the following: a passport number and country of
                    issuance, or other similar identification number, such as an alien
                    identification card number, or number and country of issuance of any other
                    government-issued document evidencing nationality or residence and bearing a
                    photograph or other similar safeguard (for non-U.S. persons).<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">For
                    verification, we will describe any document relied on (noting the type, any
                    identification number, place of issuance and, if any, date of issuance and
                    expiration). We will also describe any non-documentary methods and the results
                    of any measures undertaken.</p>
                <p class="MsoHeader" style="line-height: 150%; tab-stops: .5in;">
                    <strong style="mso-bidi-font-weight: normal;">b. Understanding the Nature and Purpose of Customer Relationships</strong>
                </p>
                <p class="MsoHeader" style="line-height: 150%; tab-stops: .5in;">
                    <span style="mso-bidi-font-style: italic;">We will understand the nature and
                        purpose of customer relationships for the purpose of developing a customer risk
                        profile through the following methods:
                    </span>
                </p>

                <div class="px-5">
                    <p class="MsoHeader" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-list: l15 level1 lfo14; tab-stops: .5in;">
                        <!-- [if !supportLists]-->
                        <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-bidi-font-style: italic;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span>
                        <!--[endif]-->
                        <span style="mso-bidi-font-style: italic;">The type of customer;</span>
                    </p>
                    <p class="MsoHeader" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-list: l15 level1 lfo14; tab-stops: .5in;">
                        <!-- [if !supportLists]-->
                        <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-bidi-font-style: italic;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span>
                        <!--[endif]-->
                        <span style="mso-bidi-font-style: italic;">The account or service being offered;</span>
                    </p>
                    <p class="MsoHeader" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-list: l15 level1 lfo14; tab-stops: .5in;">
                        <!-- [if !supportLists]-->
                        <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-bidi-font-style: italic;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span>
                        <!--[endif]-->
                        <span style="mso-bidi-font-style: italic;">The customer’s income;</span>
                    </p>
                    <p class="MsoHeader" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-list: l15 level1 lfo14; tab-stops: .5in;">
                        <!-- [if !supportLists]-->
                        <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-bidi-font-style: italic;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span>
                        <!--[endif]-->
                        <span style="mso-bidi-font-style: italic;">The customer’s net worth;</span>
                    </p>
                    <p class="MsoHeader" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-list: l15 level1 lfo14; tab-stops: .5in;">
                        <!-- [if !supportLists]-->
                        <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-bidi-font-style: italic;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span>
                        <!--[endif]-->
                        <span style="mso-bidi-font-style: italic;">The customer’s domicile;</span>
                    </p>
                    <p class="MsoHeader" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-list: l15 level1 lfo14; tab-stops: .5in;">
                        <!-- [if !supportLists]-->
                        <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-bidi-font-style: italic;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span>
                        <!--[endif]-->
                        <span style="mso-bidi-font-style: italic;">The customer’s principal occupation or business; and</span>
                    </p>
                    <p class="MsoHeader" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-list: l15 level1 lfo14; tab-stops: .5in;">
                        <!-- [if !supportLists]-->
                        <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; mso-bidi-font-style: italic;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span>
                        <!--[endif]-->
                        <span style="mso-bidi-font-style: italic;">In the case of existing customers, the customer’s history of activity.</span>
                    </p>
                </div>
                
                <p class="MsoHeader" style="line-height: 150%; mso-list: l7 level2 lfo2; tab-stops: list 1.25in;">
                    <strong style="mso-bidi-font-weight: normal;">c. Conducting Ongoing Monitoring to Identify and Report Suspicious Transactions</strong>
                </p>
                <p class="MsoHeader" style="line-height: 150%; tab-stops: .5in;">
                    <span style="mso-bidi-font-style: italic;">We will conduct ongoing monitoring to
                        identify and report suspicious transactions
                    </span>and, on a risk basis, maintain and update customer information, including
                    information regarding the beneficial ownership of legal entity customers,
                    <span style="mso-bidi-font-style: italic;">using the customer risk profile as a
                        baseline against which customer activity is assessed for suspicious transaction
                        reporting.</span>
                </p>

                <h2 style="margin-bottom: .0001pt; line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">7. Correspondent Accounts for Foreign Shell Banks
                    </span>
                </h2>

                <h2 style="margin-bottom: .0001pt; line-height: 150%;">
                    <span style="font-size:12pt; mso-bidi-font-size: 12.0pt; line-height: 150%;">a. Detecting and Closing Correspondent Accounts of Foreign Shell Banks</span>
                </h2>
                <h3 class="text-dark" style="font-size:15pt; margin-left: .5in; text-indent: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="mso-bidi-font-size: 12.0pt; line-height: 150%; font-weight: normal; mso-bidi-font-weight: bold;">We
                        will identify foreign bank accounts and any such account that is a correspondent
                        account (any
                        account that is established for a foreign bank to receive deposits from, or to
                        make payments or other disbursements on behalf of, the foreign bank, or to
                        handle other financial transactions related to such foreign bank)for foreign shell banks. Upon finding or suspecting such accounts, companyemployees will notify the AML Compliance Person, who will terminate any verifiedcorrespondent account in the United States for a foreign shell bank. We willalso terminate any correspondent account that we have determined is notmaintained by a foreign shell bank but is being used to provide services to sucha shell bank. We will exercise caution regarding liquidating positions in suchaccounts and take reasonable steps to ensure that no new positions areestablished in these accounts during the termination period. We will terminateany correspondent account for which we have not obtained the informationdescribed in Appendix A of the regulations regarding shell banks within the timeperiods specified in those regulations.<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </h3>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <strong>b. Certifications</strong>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">We will
                    require our foreign bank account holders to identify the owners of the foreign
                    bank if it is not publicly traded, the name and street address of a person who
                    resides in the United States and is authorized and has agreed to act as agent
                    for acceptance of legal process, and an assurance that the foreign bank is not a
                    shell bank nor is it facilitating activity of a shell bank. In lieu of this
                    information the foreign bank may submit the Certification Regarding
                    Correspondent Accounts For Foreign Banks provided in the BSA regulations. We
                    will re-certify when we believe that the information is no longer accurate or at
                    least once every three years.</p>
                
                
                <h2 style="margin-left: 0in; text-indent: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12pt;">c. Recordkeeping for Correspondent Accounts for Foreign Banks</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">We will keep records identifying the owners of
                        foreign banks with U.S. correspondent accounts and the name and address of the
                        U.S. agent for service of legal process for those banks.</p>
                
                <h2 style="margin-left: 1.0in; text-indent: -.5in; line-height: 150%; mso-list: none; tab-stops: -1.75in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">d. Summons or Subpoena of Foreign Bank Records; Termination of CorrespondentRelationships with Foreign Bank</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">When we
                    receive a written request from a federal law enforcement officer for information
                    identifying the non-publicly traded owners of any foreign bank for which we
                    maintain a correspondent account in the United States and/or the name and
                    address of a person residing in the United States who is an agent to accept
                    service of legal process for a foreign bank’s correspondent account, we
                    will provide that information to the requesting officer not later than seven
                    days after receipt of the request. We will close, within 10 days, any
                    correspondent account for a foreign bank that we learn from FinCEN or the
                    Department of Justice has failed to comply with a summons or subpoena issued by
                    the Secretary of the Treasury or the Attorney General of the United States or
                    has failed to contest such a summons or subpoena. We will scrutinize any
                    correspondent account activity during that 10-day period to ensure that any
                    suspicious activity is appropriately reported and to ensure that no new
                    positions are established in these correspondent accounts.</p>
                
                    
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">8. Due Diligence and Enhanced Due Diligence Requirements for CorrespondentAccounts of Foreign Financial Institutions</span>
                </h2>
                <h2 style="margin-left: 1.0in; text-indent: -.5in; line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">a.<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>Due Diligence for Correspondent Accounts of Foreign Financial Institutions</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">We
                    will conduct an inquiry to determine whether a foreign financial institution has
                    a correspondent account established, maintained, administered or managed by the
                    firm.</p>
                
                <p class="MsoNormal" style="line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">If
                    we have correspondent accounts for foreign financial institutions, we will
                    assess the money laundering risk posed, based on a consideration of relevant
                    risk factors. We can apply all or a subset of these risk factors depending on
                    the nature of the foreign financial institutions and the relative money
                    laundering risk posed by such institutions.</p>
                
                <p class="MsoNormal" style="line-height: 150%;">The relevant risk factors can include:</p>
                
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.25in; line-height: 150%; mso-list: l3 level1 lfo7; tab-stops: list 1.0in;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->the nature of the foreign financial institution’s
                        business and the markets it serves;</p>
                
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.25in; line-height: 150%; mso-list: l3 level1 lfo7; tab-stops: list 1.0in;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->the type, purpose and anticipated activity of such correspondent account;</p>
                
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.25in; line-height: 150%; mso-list: l3 level1 lfo7; tab-stops: list 1.0in;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->the nature and duration of the firm’s relationship
                        with the foreign financial institution and its affiliates;</p>
                
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.25in; line-height: 150%; mso-list: l3 level1 lfo7; tab-stops: list 1.0in;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->the anti-money laundering and supervisory regime of the
                        jurisdiction that issued the foreign financial institution’s charter or
                        license and, to the extent reasonably available, the jurisdiction in which any
                        company that is an owner of the foreign financial institution is incorporated or
                        chartered; and</p>
                
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.25in; line-height: 150%; mso-list: l3 level1 lfo7; tab-stops: list 1.0in;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->information known or reasonably available to the covered
                        financial institution about the foreign financial institution’s anti-money
                        laundering record.<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">In
                    addition, our due diligence program will consider additional factors that have
                    not been enumerated above when assessing foreign financial institutions that
                    pose a higher risk of money laundering.</p>
                
                <p class="MsoNormal" style="line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">We
                    will apply our risk-based due diligence procedures and controls to each
                    financial foreign institution correspondent account on an ongoing basis. This
                    includes periodically reviewing the activity of each foreign financial
                    institution correspondent sufficient to ensure whether the nature and volume of
                    account activity is generally consistent with the information regarding the
                    purpose and expected account activity and to ensure that the firm can adequately
                    identify suspicious transactions. Ordinarily, we will not conduct this periodic
                    review by scrutinizing every transaction taking place within the account. One
                    procedure we may use instead is to use any account profiles for our
                    correspondent accounts (to the extent we maintain these) that we ordinarily use
                    to anticipate how the account might be used and the expected volume of activity
                    to help establish baselines for detecting unusual activity.</p>
                                
                <p class="MsoNormal" style="line-height: 150%;">
                    <strong style="mso-bidi-font-weight: normal;">b. Enhanced Due Diligence</strong>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">We
                    will assess any correspondent accounts for foreign financial institutions to
                    determine whether they are correspondent accounts that have been established,
                    maintained, administered or managed for any foreign bank that operates under:<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(1) an offshore banking license;</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(2)
                    a banking license issued by a foreign country that has been designated as
                    non-cooperative with international anti-money laundering principles or
                    procedures by an intergovernmental group or organization of which the United
                    States is a member and with which designation the U.S. representative to the
                    group or organization concurs; or</p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(3)
                    a banking license issued by a foreign country that has been designated by the
                    Secretary of the Treasury as warranting special measures due to money laundering
                    concerns.</p>
                <p class="MsoNormal" style="line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">If
                    we determine that we have any correspondent accounts for these specified foreign
                    banks, we will perform enhanced due diligence on these correspondent accounts.
                    The enhanced due diligence that we will perform for each correspondent account
                    will include, at a minimum, procedures to take reasonable steps to:</p>
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(1)<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>conduct enhanced scrutiny of the correspondent account to guard againstmoney laundering and to identify and report any suspicious transactions. Suchscrutiny will not only reflect risk assessment but will also include proceduresto, as appropriate:</p>
                <p class="MsoNormal" style="margin-left: 1.5in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(i)<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>obtain (<em style="mso-bidi-font-style: normal;">e.g.,</em>
                    using a questionnaire) and consider information related to the foreign
                    bank’s AML program to assess the extent to which the foreign bank’s
                    correspondent account may expose us to any risk of money laundering;</p>
                <p class="MsoNormal" style="margin-left: 1.5in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(ii)<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>monitor transactions to, from or through the correspondent account in amanner reasonably designed to detect money laundering and suspicious activity(this monitoring may be conducted manually or electronically and may be done onan individual account basis or by product activity); and</p>
                <p class="MsoNormal" style="margin-left: 1.5in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(iii)<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>obtain information from the foreign bank about the identity of any personwith authority to direct transactions through any correspondent account that isa payable-through account (a correspondent account maintained for a foreign bankthrough which the foreign bank permits its customer to engage, either directlyor through a subaccount, in banking activities) and the sources and beneficialowners of funds or other assets in the payable-through account.</p>
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(2)<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>determine whether the foreign bank maintains correspondent accounts forother foreign banks that enable those other foreign banks to gain access to thecorrespondent account under review and, if so, to take reasonable steps toobtain information to assess and mitigate the money laundering risks associatedwith such accounts, including, as appropriate, the identity of those otherforeign banks; and</p>
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(3)<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>if the foreign bank’s shares are not publicly traded, determine theidentity of each owner and the nature and extent of each owner’s ownershipinterest. We understand that for purposes of determining a private foreignbank’s ownership, an “owner” is any person who directly orindirectly owns, controls or has the power to vote 10 percent or more of anyclass of securities of a foreign bank. We also understand that members of thesame family shall be considered to be one person.</p>
                
                <p class="MsoNormal" style="mline-height: 150%;">
                    <strong style="mso-bidi-font-weight: normal;">c. Special Procedures When Due Diligence or Enhanced Due Diligence Cannot BePerformed</strong>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">In the
                    event there are circumstances in which we cannot perform appropriate due
                    diligence with respect to a correspondent account, we will determine, at a
                    minimum, whether to refuse to open the account, suspend transaction activity,
                    file a SAR, close the correspondent account and/or take other appropriate
                    action.</p>
                

                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; mso-bidi-font-weight: bold;">9. Due Diligence and Enhanced Due Diligence Requirements for Private BankingAccounts/Senior Foreign Political Figures
                    </span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">We do not open or maintain private banking accounts<em style="mso-bidi-font-style: normal;">.</em>
                </p>
                
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">10. Compliance with FinCEN’s Issuance of Special Measures AgainstForeign Jurisdictions, Financial Institutions or International Transactions ofPrimary Money Laundering Concern</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">If<strong style="mso-bidi-font-weight: normal;"></strong>FinCEN issues a final rule imposing a special measure against<strong style="mso-bidi-font-weight: normal;"></strong>oneor more foreign jurisdictions or financial institutions, classes ofinternational transactions or types of accounts
                    <span style="color: black;">deeming them to be of primary money laundering
                        concern, we understand that we must read FinCEN’s final rule and follow
                        any prescriptions or prohibitions contained in that rule. For example, if the
                        final rule deems a certain bank and its subsidiaries (Specified Banks) to be of
                        primary money laundering concern, a special measure may be a prohibition from
                        opening or maintaining a correspondent account in the United States for, or on
                        behalf of, the Specified Banks. In that case, we will take the following steps:</span>
                </p>
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">
                    <span style="color: black;">(1)<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>We will review our account records, including correspondent accountrecords, to ensure that our accountholders and correspondent accountholdersmaintain no accounts directly for, or on behalf of, the Specified Banks; and</span>
                </p>
                <p class="MsoNormal" style="margin-left: 1.0in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">(2)<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>We will apply due diligence procedures to our correspondent accounts thatare reasonably designed to guard against indirect use of those accounts by theSpecified Banks. Such due diligence may include:</p>
                <p class="MsoNormal" style="margin-left: 1.5in; text-indent: -.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">
                    <span style="mso-bidi-font-style: italic;">•<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>Notification to Correspondent Accountholders</span>
                </p>
                <p class="MsoNormal" style="margin-left: 1.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">We
                    will notify our correspondent accountholders that the account may not be used to
                    provide the Specified Banks with access to us.</p>
                <p class="MsoNormal" style="margin-left: 1.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">We
                    will transmit the notice to our correspondent accounts using any appropriate
                    method, and we shall retain documentation of such notice.</p>
                <p class="MsoNormal" style="margin-left: 1.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">
                    Identification of Indirect Use
                </p>
                
                <p class="MsoNormal" style="margin-left: 1.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">
                    <span style="color: black;">We will take reasonable steps in order to identify
                        any indirect use of our correspondent accounts by the Specified Banks. We will
                        determine if such indirect use is occurring from transactional records that we
                        maintain in the normal course of business. We will take a risk-based approach
                        when deciding what, if any, additional due diligence measures we should adopt to
                        guard against the indirect use of correspondent accounts by the Specified Banks,
                        based on risk factors such as the type of services offered by, and geographic
                        locations of, their correspondents.
                    </span>
                </p>
                
                <p class="MsoNormal" style="margin-left: 1.5in; line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">
                    <span style="color: black;">We understand that we have an ongoing obligation to
                        take reasonable steps to identify all correspondent account services our
                        correspondent accountholders may directly or indirectly provide to the Specified
                        Banks.</span>
                </p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">11.<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>Monitoring Accounts for Suspicious Activity</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will monitor account activity for
                        unusual size, volume, pattern or type of transactions, taking into account risk
                        factors and red flags that are appropriate to our business. Monitoring will be
                        conducted through the following methods:
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will use tools that can generate
                        alerts, taking into account the different financial products and suspicious
                        activities would be escalated on time.</span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">The
                    customer risk profile will serve as a baseline for assessing potentially
                    suspicious activity.
                    <span style="mso-bidi-font-style: italic;">The AML Compliance Person or his or
                        her designee will be responsible for this monitoring, will review any activity
                        that our monitoring system detects, will determine whether any additional steps
                        are required, will document when and how this monitoring is carried out, and
                        will report suspicious activities to the appropriate authorities.
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will conduct the reviews of
                        activity that our monitoring system detects. We will document our monitoring and
                        reviews through explanation of the warning signs identified, additional
                        information enabling the identification of the destination of funds, receipts
                        allowing the recognition of the financial transactions reported, provide
                        elements of the “Know Your Customer” process that help determine the
                        customer’s financial behavior. The<span style="mso-bidi-font-weight: bold;">
                            AML Compliance Person or his or her designee will conduct an appropriate
                            investigation and review relevant information from internal or third-party
                            sources before a SAR is filed. Relevant information can include, but not be
                            limited to, the following: name and address of the perpetrator.</span>
                    </span>
                </p>
                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">a. Emergency Notification to Law Enforcement by Telephone<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">In situations involving violations
                        that require immediate attention, such as terrorist financing or ongoing money
                        laundering schemes, we will immediately call an appropriate law enforcement
                        authority. If a customer or company appears on OFAC’s SDN list, we will
                        call the OFAC Hotline. Other contact numbers we will use are: FinCEN’s
                        Financial Institutions Hotline (especially to report transactions relating to
                        terrorist activity), local U.S. Attorney’s office, local FBI office and
                        local SEC office<span style="mso-bidi-font-style: italic;">
                            (to voluntarily report such violations to the SEC in addition to contacting the
                            appropriate law enforcement authority)</span>.If we notify the appropriate law enforcement authority of any such activity, wemust still file a timely a SAR.</span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">Although we
                    are not required to, in cases where we have filed a SAR that may require
                    immediate attention by the SEC, we may contact the SEC via the SEC SAR Alert
                    Message Line to alert the SEC about the filing. We understand that calling the
                    SEC SAR Alert Message Line does not alleviate our obligations to file a SAR or
                    notify an appropriate law enforcement authority.</p>
                
                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">b. Red Flags</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">Red flags
                    that signal possible money laundering or terrorist financing include, but are
                    not limited to:</p>
                
                <h2 style="margin-left: -.75pt; line-height: 150%; tab-stops: center 190.55pt;">
                    <span style="font-size: 12.0pt; line-height: 150%;">Potential Red Flags in Customer Due Diligence and Interactions with Customers</span>
                </h2>
                <div class="px-5">
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer provides the firm with unusual or suspicious
                            identification documents that cannot be readily verified or are inconsistent
                            with other statements or documents that the customer has provided. Or, the
                            customer provides information that is inconsistent with other available
                            information about the customer. This indicator may apply to account openings and
                            to interaction subsequent to account opening.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer is reluctant or refuses to provide the firm
                            with complete customer due diligence information as required by the firm’s
                            procedures, which may include information regarding the nature and purpose of
                            the customer’s business, prior financial relationships, anticipated
                            account activity, business location and, if applicable, the entity’s
                            officers and directors.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer refuses to identify a legitimate source of
                            funds or information is false, misleading or substantially incorrect.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer is domiciled in, doing business in or
                            regularly transacting with counterparties in a jurisdiction that is known as a
                            bank secrecy haven, tax shelter, high-risk geographic location (<em style="mso-bidi-font-style: normal;">e.g.</em>,known as a narcotics producing jurisdiction, known to have ineffectiveAML/Combating the Financing of Terrorism systems) or conflict zone, includingthose with an established threat of terrorism.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer has difficulty describing the nature of his or
                            her business or lacks general knowledge of his or her industry.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer has no discernable reason for using the
                            firm’s service or the firm’s location (<em style="mso-bidi-font-style: normal;">e.g.</em>,the customer lacks roots to the local community or has gone out of his or herway to use the firm).</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer has been rejected or has had its relationship
                            terminated as a customer by other financial services firms.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer’s legal or mailing address is associated
                            with multiple other accounts or businesses that do not appear related.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer appears to be acting as an agent for an
                            undisclosed principal, but is reluctant to provide information.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer is a trust, shell company or private
                            investment company that is reluctant to provide information on controlling
                            parties and underlying beneficiaries.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer is publicly known or known to the firm to have
                            criminal, civil or regulatory proceedings against him or her for crime,
                            corruption or misuse of public funds, or is known to associate with such
                            persons. Sources for this information could include news items, the Internet or
                            commercial database searches.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer’s background is questionable or differs
                            from expectations based on business activities.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer maintains multiple accounts, or maintains
                            accounts in the names of family members or corporate entities, with no apparent
                            business or other purpose.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->An account is opened by a politically exposed person (PEP),<sup>9</sup>
                        particularly in conjunction with one or more additional risk factors, such as
                        the account being opened by a shell company<sup>10</sup>
                        beneficially owned or controlled by the PEP, the PEP is from a country which has
                        been identified by FATF as having strategic AML regime deficiencies, or the PEP
                        is from a country known to have a high level of corruption.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->An account is opened by a non-profit organization that
                            provides services in geographic locations known to be at higher risk for being
                            an active terrorist threat.<sup>11</sup>
                    </p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->An account is opened in the name of a legal entity that is
                            involved in the activities of an association, organization or foundation whose
                            aims are related to the claims or demands of a known terrorist entity.<sup>12</sup>
                    </p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->An account is opened for a purported stock loan company,
                            which may hold the restricted securities of corporate insiders who have pledged
                            the securities as collateral for, and then defaulted on, purported loans, after
                            which the securities are sold on an unregistered basis.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->An account is opened in the name of a foreign financial
                            institution, such as an offshore bank or broker-dealer, that sells shares of
                            stock on an unregistered basis on behalf of customers.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l2 level1 lfo15; margin: 0in 0in 6.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->An account is opened for a foreign financial institution
                            that is affiliated with a U.S. broker-dealer, bypassing its U.S. affiliate, for
                            no apparent business purpose. An apparent business purpose could include access
                            to products or services the U.S. affiliate does not provide.</p>
                </div>
                <h2 style="margin-left: -.75pt; line-height: 150%; tab-stops: center 111.85pt;">
                    <span style="font-size: 12.0pt; line-height: 150%;">Potential Red Flags in Deposits of Securities</span>
                </h2>
                <div class="px-5">
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l1 level1 lfo16; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer opens a new account and deposits physical
                            certificates, or delivers in shares electronically, representing a large block
                            of thinly traded or low-priced securities.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l1 level1 lfo16; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer has a pattern of depositing physical share
                            certificates, or a pattern of delivering in shares electronically, immediately
                            selling the shares and then wiring, or otherwise transferring out the proceeds
                            of the sale(s).</p>
                    <p class="MsoNormal" style=" line-height: 150%; mso-list: l1 level1 lfo16;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer deposits into an account physical share
                            certificates or electronically deposits or transfers shares that:</p>
                    <p class="MsoNormal" style="margin-left: 28.05pt; text-indent: -9.35pt; line-height: 150%; mso-list: l6 level2 lfo21;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: 'Courier New'; mso-fareast-font-family: 'Courier New'; color: black; mso-bidi-font-weight: bold;">
                            <span style="mso-list: Ignore;">o<span style="font: 7.0pt 'Times New Roman';"></span>
                            </span>
                        </span><!--[endif]-->were recently issued or represent a large percentage of the
                            float for the security;</p>
                    <p class="MsoNormal" style="margin-left: 28.05pt; text-indent: -9.35pt; line-height: 150%; mso-list: l6 level2 lfo21;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: 'Courier New'; mso-fareast-font-family: 'Courier New'; color: black; mso-bidi-font-weight: bold;">
                            <span style="mso-list: Ignore;">o<span style="font: 7.0pt 'Times New Roman';"></span>
                            </span>
                        </span><!--[endif]-->reference a company or customer name that has been changed
                            or that does not match the name on the account;</p>
                    <p class="MsoNormal" style="margin-left: 28.05pt; text-indent: -9.35pt; line-height: 150%; mso-list: l6 level2 lfo21;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: 'Courier New'; mso-fareast-font-family: 'Courier New'; color: black; mso-bidi-font-weight: bold;">
                            <span style="mso-list: Ignore;">o<span style="font: 7.0pt 'Times New Roman';"></span>
                            </span>
                        </span><!--[endif]-->were issued by a shell company;</p>
                    <p class="MsoNormal" style="margin-left: 28.05pt; text-indent: -9.35pt; line-height: 150%; mso-list: l6 level2 lfo21;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: 'Courier New'; mso-fareast-font-family: 'Courier New'; color: black; mso-bidi-font-weight: bold;">
                            <span style="mso-list: Ignore;">o<span style="font: 7.0pt 'Times New Roman';"></span>
                            </span>
                        </span><!--[endif]-->were issued by a company that has no apparent business, revenues or products;</p>
                    <p class="MsoNormal" style="margin-left: 28.05pt; text-indent: -9.35pt; line-height: 150%; mso-list: l6 level2 lfo21;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: 'Courier New'; mso-fareast-font-family: 'Courier New'; color: black; mso-bidi-font-weight: bold;">
                            <span style="mso-list: Ignore;">o<span style="font: 7.0pt 'Times New Roman';"></span>
                            </span>
                        </span><!--[endif]-->were issued by a company whose SEC filings are not current,
                            are incomplete, or nonexistent;</p>
                    <p class="MsoNormal" style="margin-left: 28.05pt; text-indent: -9.35pt; line-height: 150%; mso-list: l6 level2 lfo21;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: 'Courier New'; mso-fareast-font-family: 'Courier New'; color: black; mso-bidi-font-weight: bold;">
                            <span style="mso-list: Ignore;">o<span style="font: 7.0pt 'Times New Roman';"></span>
                            </span>
                        </span><!--[endif]-->were issued by a company that has been through several
                            recent name changes or business combinations or recapitalizations;</p>
                    <p class="MsoNormal" style="margin-left: 28.05pt; text-indent: -9.35pt; line-height: 150%; mso-list: l6 level2 lfo21;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: 'Courier New'; mso-fareast-font-family: 'Courier New'; color: black; mso-bidi-font-weight: bold;">
                            <span style="mso-list: Ignore;">o<span style="font: 7.0pt 'Times New Roman';"></span>
                            </span>
                        </span><!--[endif]-->were issued by a company that has been the subject of a
                            prior trading suspension; or</p>
                    <p class="MsoNormal" style="text-indent: -9.35pt; line-height: 150%; mso-list: l6 level2 lfo21; margin: 0in 0in 12.0pt 28.05pt;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: 'Courier New'; mso-fareast-font-family: 'Courier New'; color: black; mso-bidi-font-weight: bold;">
                            <span style="mso-list: Ignore;">o<span style="font: 7.0pt 'Times New Roman';"></span>
                            </span>
                        </span><!--[endif]-->were issued by a company whose officers or insiders have a
                            history of regulatory or criminal violations, or are associated with multiple
                            low-priced stock issuers.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l1 level1 lfo16; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The lack of a restrictive legend on deposited shares seems
                            inconsistent with the date the customer acquired the securities, the nature of
                            the transaction in which the securities were acquired, the history of the stock
                            or the volume of shares trading.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l1 level1 lfo16; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer with limited or no other assets at the firm
                            receives an electronic transfer or journal transfer of large amounts of
                            low-priced, non-exchange-listed securities.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l1 level1 lfo16; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer’s explanation or documents purporting to
                            evidence how the customer acquired the shares does not make sense or changes
                            upon questioning by the firm or other parties. Such documents could include
                            questionable legal opinions or securities purchase agreements.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l1 level1 lfo16; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer deposits physical securities or delivers in
                            shares electronically, and within a short timeframe, requests to journal the
                            shares into multiple accounts that do not appear to be related, or to sell or
                            otherwise transfer ownership of the shares.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l1 level1 lfo16; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Seemingly unrelated clients open accounts on or at about
                            the same time, deposit the same low-priced security and subsequently liquidate
                            the security in a manner that suggests coordination.</p>
                </div>
                <h2 style="line-height: 150%; margin: 0in 0in 5.15pt -.25pt;">
                    <span style="font-size: 12.0pt; line-height: 150%;">Potential Red Flags in Securities Trading</span>
                </h2>
                <div class="px-5">
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer, for no apparent reason or in conjunction with
                            other “red flags,” engages in transactions involving certain types
                            of securities, such as penny stocks, Regulation “S” stocks and
                            bearer bonds, which although legitimate, have been used in connection with
                            fraudulent schemes and money laundering activity. (Such transactions may warrant
                            further due diligence to ensure the legitimacy of the customer’s
                            activity.)</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->There is a sudden spike in investor demand for, coupled
                            with a rising price in, a thinly traded or low-priced security.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer’s activity represents a significant
                            proportion of the daily trading volume in a thinly traded or low-priced
                            security.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer buys and sells securities with no discernable
                            purpose or circumstances that appear unusual.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Individuals known throughout the industry to be stock
                            promoters sell securities through the broker-dealer.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer accumulates stock in small increments throughout
                            the trading day to increase price.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer engages in pre-arranged or other non-competitive
                            securities trading, including wash or cross trades, with no apparent business
                            purpose.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer attempts to influence the closing price of a
                            stock by executing purchase or sale orders at or near the close of the market.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer engages in transactions suspected to be
                            associated with cyber breaches of customer accounts, including potentially
                            unauthorized disbursements of funds or trades.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer engages in a frequent pattern of placing orders
                            on one side of the market, usually inside the existing National Best Bid or
                            Offer (NBBO), followed by the customer entering orders on the other side of the
                            market that execute against other market participants that joined the market at
                            the improved NBBO (activity indicative of “spoofing”).</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A customer engages in a frequent pattern of placing
                            multiple limit orders on one side of the market at various price levels,
                            followed by the customer entering orders on the opposite side of the market that
                            are executed and the customer cancelling the original limit orders (activity
                            indicative of “layering”).</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Two or more unrelated customer accounts at the firm trade
                            an illiquid or low-priced security suddenly and simultaneously.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer makes a large purchase or sale of a security,
                            or option on a security, shortly before news or a significant announcement is
                            issued that affects the price of the security.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer is known to have friends or family who work at
                            or for the securities issuer, which may be a red flag for potential insider
                            trading or unlawful sales of unregistered securities.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer’s purchase of a security does not
                            correspond to the customer’s investment profile or history of transactions
                            (<em style="mso-bidi-font-style: normal;">e.g.</em>,the customer may never have invested in equity securities or may have neverinvested in a given industry, but does so at an opportune time) and there is noreasonable explanation for the change.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The account is using a master/sub structure, which enables
                            trading anonymity with respect to the sub-accounts’ activity, and engages
                            in trading activity that raises red flags, such as the liquidation of microcap
                            issuers or potentially manipulative trading activity.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The firm receives regulatory inquiries or grand jury or
                            other subpoenas concerning the firm’s customers’ trading.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer engages in a pattern of transactions in
                            securities indicating the customer is using securities to engage in currency
                            conversion. For example, the customer delivers in and subsequently liquidates
                            American Depository Receipts (ADRs) or dual currency bonds for U.S. dollar
                            proceeds, where the securities were originally purchased in a different
                            currency.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer engages in mirror trades or transactions
                            involving securities used for currency conversions, potentially through the use
                            of offsetting trades.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l4 level1 lfo17; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer appears to buy or sell securities based on
                            advanced knowledge of pending customer orders.</p>
                </div>
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">Potential Red Flags in Money Movements</span>
                </h2>
                <div class="px-5">
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer attempts or makes frequent or large deposits
                            of currency, insists on dealing only in cash equivalents, or asks for exemptions
                            from the firm’s policies and procedures relating to the deposit of cash
                            and cash equivalents.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer “structures” deposits, withdrawals
                            or purchases of monetary instruments below a certain amount to avoid reporting
                            or recordkeeping requirements, and may state directly that they are trying to
                            avoid triggering a reporting obligation or to evade taxing authorities.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer seemingly breaks funds transfers into smaller
                            transfers to avoid raising attention to a larger funds transfer. The smaller
                            funds transfers do not appear to be based on payroll cycles, retirement needs,
                            or other legitimate regular deposit and withdrawal strategies.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer’s account shows numerous currency, money
                            order (particularly sequentially numbered money orders) or cashier’s check
                            transactions aggregating to significant sums without any apparent business or
                            lawful purpose.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer frequently changes bank account details or
                            information for redemption proceeds, in particular when followed by redemption
                            requests.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer makes a funds deposit followed by an immediate
                            request that the money be wired out or transferred to a third party, or to
                            another firm, without any apparent business purpose.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Wire transfers are made in small amounts in an apparent
                            effort to avoid triggering identification or reporting requirements.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Incoming payments are made by third-party checks or checks
                            with multiple endorsements.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Outgoing checks to third parties coincide with, or are
                            close in time to, incoming checks from other third parties.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Payments are made by third party check or money transfer
                            from a source that has no apparent connection to the customer.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Wire transfers are made to or from financial secrecy
                            havens, tax havens, high-risk geographic locations or conflict zones, including
                            those with an established presence of terrorism.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Wire transfers originate from jurisdictions that have been
                            highlighted in relation to black market peso exchange activities.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer engages in transactions involving foreign
                            currency exchanges that are followed within a short time by wire transfers to
                            locations of specific concern (<em style="mso-bidi-font-style: normal;">e.g.</em>,countries designated by national authorities, such as FATF, as non-cooperativecountries and territories).</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The parties to the transaction (<em style="mso-bidi-font-style: normal;">e.g.</em>,originator or beneficiary) are from countries that are known to supportterrorist activities and organizations.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Wire transfers or payments are made to or from unrelated
                            third parties (foreign or domestic), or where the name or account number of the
                            beneficiary or remitter has not been supplied.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->There is wire transfer activity that is unexplained,
                            repetitive, unusually large, shows unusual patterns or has no apparent business
                            purpose.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The securities account is used for payments or outgoing
                            wire transfers with little or no securities activities (<em style="mso-bidi-font-style: normal;">i.e.</em>,account appears to be used as a depository account or a conduit for transfers,which may be purported to be for business operating needs).</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Funds are transferred to financial or depository
                            institutions other than those from which the funds were initially received,
                            specifically when different countries are involved.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer engages in excessive journal entries of funds
                            between related or unrelated accounts without any apparent business purpose.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer uses a personal/individual account for
                            business purposes or vice versa.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A foreign import business with U.S. accounts receives
                            payments from outside the area of its customer base.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->There are frequent transactions involving round or whole
                            dollar amounts purported to involve payments for goods or services.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Upon request, a customer is unable or unwilling to produce
                            appropriate documentation (<em style="mso-bidi-font-style: normal;">e.g.</em>,invoices) to support a transaction, or documentation appears doctored or fake (<em style="mso-bidi-font-style: normal;">e.g.</em>,documents contain significant discrepancies between the descriptions on thetransport document or bill of lading, the invoice, or other documents such asthe certificate of origin or packing list).</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer requests that certain payments be routed through nostro<sup>14</sup>
                        or correspondent accounts held by the financial intermediary instead of its own
                        accounts, for no apparent business purpose.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Funds are transferred into an account and are subsequently
                            transferred out of the account in the same or nearly the same amounts,
                            especially when the origin and destination locations are high-risk
                            jurisdictions.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A dormant account suddenly becomes active without a plausible explanation (<em style="mso-bidi-font-style: normal;">e.g.</em>, large deposits that are suddenly wired out).</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Nonprofit or charitable organizations engage in financial
                            transactions for which there appears to be no logical economic purpose or in
                            which there appears to be no link between the stated activity of the
                            organization and the other parties in the transaction.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->There is unusually frequent domestic and international
                            automated teller machine (ATM) activity.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A person customarily uses the ATM to make several deposits
                            into a brokerage account below a specified BSA/AML reporting threshold.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Many small, incoming wire transfers or deposits are made
                            using checks and money orders that are almost immediately withdrawn or wired out
                            in a manner inconsistent with the customer’s business or history; the
                            checks or money orders may reference in a memo section “investment”
                            or “for purchase of stock.” This may be an indicator of a Ponzi
                            scheme or potential funneling activity.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l14 level1 lfo18; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Wire transfer activity, when viewed over a period of time,
                            reveals suspicious or unusual patterns, which could include round dollar,
                            repetitive transactions or circuitous money movements.</p>
                </div>
                <h2 style="margin-left: -.75pt; line-height: 150%; tab-stops: center 106.35pt;">
                    <span style="font-size: 12.0pt; line-height: 150%;">Potential Red Flags in Insurance Products</span>
                </h2>
                <div class="px-5">
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l17 level1 lfo19; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer cancels an insurance contract and directs that
                            the funds be sent to a third party.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l17 level1 lfo19; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer deposits an insurance annuity check from a
                            cancelled policy and immediately requests a withdrawal or transfer of funds.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l17 level1 lfo19; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer cancels an annuity product within the
                            free-look period. This could be a red flag if accompanied with suspicious
                            indicators, such as purchasing the annuity with several sequentially numbered
                            money orders or having a history of cancelling annuity products during the
                            free-look period.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l17 level1 lfo19; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer opens and closes accounts with one insurance
                            company, then reopens a new account shortly thereafter with the same insurance
                            company, each time with new ownership information.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l17 level1 lfo19; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer purchases an insurance product with no concern
                            for the investment objective or performance.</p>
                </div>

                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">Other Potential Red Flags</span>
                </h2>
                <div class="px-5">
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer is reluctant to provide information needed to
                            file reports to proceed with the transaction.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer exhibits unusual concern with the firm’s
                            compliance with government reporting requirements and the firm’s AML
                            policies.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer tries to persuade an employee not to file
                            required reports or not to maintain the required records.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Notifications received from the broker-dealer’s
                            clearing firm that the clearing firm had identified potentially suspicious
                            activity in customer accounts. Such notifications can take the form of alerts or
                            other concern regarding negative news, money movements or activity involving
                            certain securities.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Law enforcement has issued subpoenas or freeze letters
                            regarding a customer or account at the securities firm.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer makes high-value transactions not commensurate
                            with the customer’s known income or financial resources.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer wishes to engage in transactions that lack
                            business sense or an apparent investment strategy, or are inconsistent with the
                            customer’s stated business strategy.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The stated business, occupation or financial resources of
                            the customer are not commensurate with the type or level of activity of the
                            customer.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer engages in transactions that show the customer
                            is acting on behalf of third parties with no apparent business or lawful
                            purpose.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer engages in transactions that show a sudden
                            change inconsistent with normal activities of the customer.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->Securities transactions are unwound before maturity, absent
                            volatile market conditions or other logical or apparent reason.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->The customer does not exhibit a concern with the cost of
                            the transaction or fees (<em style="mso-bidi-font-style: normal;">e.g.</em>, surrender fees, or higher than necessary commissions).</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->A borrower defaults on a cash-secured loan or any loan that
                            is secured by assets that are readily convertible into currency.</p>
                    <p class="MsoNormal" style="text-indent: -.25in; line-height: 150%; mso-list: l13 level1 lfo20; margin: 0in 0in 12.0pt .25in;">
                        <!-- [if !supportLists]-->
                        <span style="font-size: 10.0pt; line-height: 150%; font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol; color: black;">
                            <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                        </span><!--[endif]-->There is an unusual use of trust funds in business
                            transactions or other financial activity.</p>
                </div>

                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">c. Responding to Red Flags and Suspicious Activity</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">When an
                    employee of the firm detects any red flag, or other activity that may be
                    suspicious, he or she will notify the Head of Operations. Under the direction of
                    the AML Compliance Person, the firm will determine whether or not and how to
                    further investigate the matter. This may include gathering additional
                    information internally or from third-party sources, contacting the government,
                    freezing the account and/or filing a SAR.</p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">12. Suspicious Transactions and BSA Reporting<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </h2>

                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">a. Filing a SAR</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will file SARs with FinCEN for any
                        transactions (including deposits and transfers) conducted or attempted by, at or
                        through our firm involving $5,000 or more of funds or assets (either
                        individually or in the aggregate) where we know, suspect or have reason to
                        suspect:
                    </span>
                </p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">(1) the transaction involves funds
                        derived from illegal activity or is intended or conducted in order to hide or
                        disguise funds or assets derived from illegal activity as part of a plan to
                        violate or evade federal law or regulation or to avoid any transaction reporting
                        requirement under federal law or regulation;
                    </span>
                </p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">(2) the transaction is designed,
                        whether through structuring or otherwise, to evade any requirements of the BSA
                        regulations;
                    </span>
                </p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">(3) the transaction has no business
                        or apparent lawful purpose or is not the sort in which the customer would
                        normally be expected to engage, and after examining the background, possible
                        purpose of the transaction and other facts, we know of no reasonable explanation
                        for the transaction; or
                    </span>
                </p>
                <p class="MsoNormal" style="margin-left: .5in; text-indent: -.25in; line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">(4) the transaction involves the use
                        of the firm to facilitate criminal activity.<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will also file a SAR and notify
                        the appropriate law enforcement authority in situations involving violations
                        that require immediate attention, such as terrorist financing or ongoing money
                        laundering schemes. In addition, although we are not required to, we may contact
                        that SEC in cases where a SAR we have filed may require immediate attention by
                        the SEC. We also understand that, even if we notify a regulator of a violation,
                        unless it is specifically covered by one of the exceptions in the SAR rule, we
                        must file a SAR reporting the violation.</span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We may file a voluntary SAR for any
                        suspicious transaction that we believe is relevant to the possible violation of
                        any law or regulation but that is not required to be reported by us under the
                        SAR rule. It is our policy that all SARs will be reported regularly to the Board
                        of Directors and appropriate senior management, with a clear reminder of the
                        need to maintain the confidentiality of the SAR.<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will report suspicious transactions
                        by completing a SAR, and we will collect and maintain supporting documentation
                        as required by the BSA regulations. We will file a SAR-SF no later than 30
                        calendar days after the date of the initial detection of the facts that
                        constitute a basis for filing a SAR. If no suspect is identified on the date of
                        initial detection, we may delay filing the SAR for an additional 30 calendar
                        days pending identification of a suspect, but in no case will the reporting be
                        delayed more than 60 calendar days after the date of initial detection. The
                        phrase “initial detection” does not mean the moment a transaction is
                        highlighted for review. The 30-day (or 60-day) period begins when an appropriate
                        review is conducted and a determination is made that the transaction under
                        review is “suspicious” within the meaning of the SAR requirements. A
                        review must be initiated promptly upon identification of unusual activity that
                        warrants investigation.</span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will retain copies of any SAR filed
                        and the original or business record equivalent of any supporting documentation
                        for five years from the date of filing the SAR-SF. We will identify and maintain
                        supporting documentation and make such information available to FinCEN, any
                        other appropriate law enforcement agencies, federal or state securities
                        regulators or SROs upon request.</span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will not notify any person involved
                        in the transaction that the transaction has been reported, except as permitted
                        by the BSA regulations. We understand that anyone who is subpoenaed or required
                        to disclose a SAR or the information contained in the SAR will, except where
                        disclosure is requested by FinCEN, the SEC, or another appropriate law
                        enforcement or regulatory agency, or an SRO registered with the SEC, decline to
                        produce the SAR or to provide any information that would disclose that a SAR was
                        prepared or filed. We will notify FinCEN of any such request and our response.</span>
                </p>
                
                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">b. Currency Transaction Reports<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">Our company prohibits transactions
                        involving currency and has the procedures to prevent such transactions. If we
                        discover such transactions have occurred, we will file with FinCEN CTRs for
                        currency transactions that exceed $10,000. Also, we will treat multiple
                        transactions involving currency as a single transaction for purposes of
                        determining whether to file a CTR if they total more than $10,000 and are made
                        by or on behalf of the same person during any one business day.</span>
                </p>

                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">c. Currency and Monetary Instrument Transportation Reports
                    </span>
                </h2>
                <p class="MsoBodyText2" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: normal; font-style: normal;">Our company
                        prohibits both the receipt of currency or other monetary instruments that have
                        been transported, mailed or shipped to us from outside of the United States, and
                        the physical transportation, mailing or shipment of currency or other monetary
                        instruments by any means other than through the postal service or by common
                        carrier. We will file a CMIR with the Commissioner of Customs if we discover
                        that we have received or caused or attempted to receive from outside of the U.S.
                        currency or other monetary instruments in an aggregate amount exceeding $10,000
                        at one time (on one calendar day or, if for the purposes of evading reporting
                        requirements, on one or more days). We will also</span>
                    <span style="font-style: normal;">
                        file a CMIR if we discover that we have physically transported, mailed or
                        shipped or caused or attempted to physically transport, mail or ship by any
                        means other than through the postal service or by common carrier currency or
                        other monetary instruments of more than $10,000 at one time (on one calendar day
                        or, if for the purpose of evading the reporting requirements, on one or more
                        days). We will use the
                        <span class="MsoHyperlink">
                            <span style="color: black; mso-themecolor: text1; text-decoration: none; text-underline: none;">
                                <a href="https://www.fincen.gov/sites/default/files/shared/fin105_cmir.pdf">
                                    <span style="color: black; mso-themecolor: text1;">CMIR</span>
                                    <span style="color: black; mso-themecolor: text1;">Form</span>
                                </a>
                            </span>
                        </span>
                        provided on FinCEN’s website.</span>
                </p>
                
                <h2 style="line-height: 150%; mso-list: l11 level1 lfo10; tab-stops: list 1.0in;">
                    <!-- [if !supportLists]-->
                    <span style="font-size: 12.0pt; line-height: 150%;">d. Foreign Bank and Financial Accounts Reports</span>
                </h2>
                <p class="MsoBodyText2" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: normal; font-style: normal;">We will file a
                        Foreign Bank and Financial Accounts Report (FBAR) for any financial accounts of
                        more than $10,000 that we hold, or for which we have signature or other
                        authority over, in a foreign country. We will use the
                        <span class="MsoHyperlink">
                            <span style="color: black; mso-themecolor: text1; text-decoration: none; text-underline: none;">
                                <a href="http://bsaefiling.fincen.treas.gov/main.html">
                                    <span style="color: black; mso-themecolor: text1;">BSA E-Filing System</span>
                                </a>
                            </span>
                        </span>
                        provided on FinCEN’s website.</span>
                </p>
                
                <h2 style="line-height: 150%; mso-list: l11 level1 lfo10; tab-stops: list 1.0in;">
                    <!-- [if !supportLists]-->
                    <span style="font-size: 12.0pt; line-height: 150%;">e. Monetary Instrument Purchase</span>
                </h2>
                
                <p class="MsoNormal" style="line-height: 150%;">We do not
                    issue bank checks or drafts, cashier’s checks, money orders or
                    traveler’s checks in the amount of $3,000 or more.</p>

                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">f. Funds Transmittals of $3,000 or More Under the Travel Rule</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">When we are
                    the transmittor’s financial institution in funds of $3,000 or more, we
                    will retain either the original or a copy (<em style="mso-bidi-font-style: normal;">e.g.</em>,microfilm, electronic record) of the transmittal order. We will also record onthe transmittal order the following information: (1) the name and address of thetransmittor; (2) if the payment is ordered from an account, the account number;(3) the amount of the transmittal order; (4) the execution date of thetransmittal order; and (5) the identity of the recipient’s financialinstitution. In addition, we will include on the transmittal order as many ofthe following items of information as are received with the transmittal order:(1) the name and address of the recipient; (2) the account number of therecipient; (3) any other specific identifier of the recipient; and (4) any formrelating to the transmittal of funds that is completed or signed by the personplacing the transmittal order.<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                
                <p class="MsoNormal" style="line-height: 150%;">We will
                    also verify the identity of the person placing the transmittal order (if we are
                    the transmitting firm), provided the transmittal order is placed in person and
                    the transmittor is not an established customer of the firm (<em style="mso-bidi-font-style: normal;">i.e.</em>,a customer of the firm who has not previously maintained an account with us orfor whom we have not obtained and maintained a file with the customer's name,address, taxpayer identification number, or, if none, alien identificationnumber or passport number and country of issuance). If a transmittor orrecipient is conducting business in person, we will obtain: (1) theperson’s name and address; (2) the type of identification reviewed and thenumber of the identification document (<em>e.g.,
                    </em>driver’s license); and (3) the person’s taxpayer identificationnumber (<em>e.g.,
                    </em>Social Security or employer identification number) or, if none, alienidentification number or passport number and country of issuance, or a notationin the record the lack thereof. If a transmittor or recipient is not conductingbusiness in person, we shall obtain the person’s name, address, and a copyor record of the method of payment (<em>e.g.,
                    </em>check or credit card transaction). In the case of transmittors only, weshall also obtain the transmittor’s taxpayer identification number (<em>e.g.,
                    </em>Social Security or employer identification number) or, if none, alienidentification number or passport number and country of issuance, or a notationin the record of the lack thereof. In the case of recipients only, we shallobtain the name and address of the person to which the transmittal was sent.</p>
                
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">13. AML Recordkeeping
                    </span>
                </h2>

                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">a. Responsibility for Required AML Records and SAR Filing
                    </span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">Our AML
                    Compliance Person and his designee will be responsible for ensuring that AML
                    records are maintained properly and that SARs are filed as required.</p>
                
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">In addition, as part of our AML
                        program, our firm will create and maintain SARs, CTRs, CMIRs, FBARs, and
                        relevant documentation on customer identity and verification
                        <em>(See</em>
                        Section 5 above) and funds transmittals. We will maintain SARs and their
                        accompanying documentation for at least five years. We will keep other documents
                        according to existing BSA and other recordkeeping requirements, including
                        certain SEC rules that require six-year retention periods (<em style="mso-bidi-font-style: normal;">e.g</em>.,Exchange Act Rule 17a-4(a) requiring firms to preserve for a period of not lessthan six years, all records required to be retained by Exchange Act Rule17a-3(a)(1)-(3), (a)(5), and (a)(21)-(22) and Exchange Act Rule 17a-4(e)(5)requiring firms to retain for six years account record information requiredpursuant to Exchange Act Rule 17a-3(a)(17)).</span>
                </p>
                
                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">b. SAR Maintenance and Confidentiality</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-weight: bold;">We will hold SARs and any supporting
                        documentation confidential. We will not inform anyone outside of FinCEN, the
                        SEC, an SRO registered with the SEC or other appropriate law enforcement or
                        regulatory agency about a SAR. We will refuse any subpoena requests for SARs or
                        for information that would disclose that a SAR has been prepared or filed and
                        immediately notify FinCEN of any such subpoena requests that we receive.
                        <em style="mso-bidi-font-style: normal;">See
                        </em>Section 11 for contact numbers. We will segregate SAR filings and copies of
                        supporting documentation from other firm books and records to avoid disclosing
                        SAR filings. Our AML Compliance Person will handle all subpoenas or other
                        requests for SARs.<em></em>
                    </span>We may share information with another financial institution about
                    suspicious transactions in order to determine whether we will jointly file a SAR<span style="mso-bidi-font-weight: bold;">
                        according to the provisions of Section 3.d</span>. In cases in which we file ajoint SAR for a transaction that has been handled both by us and anotherfinancial institution, both financial institutions will maintain a copy of thefiled SAR.</p>
                
                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">c. Additional Records</span>
                </h2>
                <p class="MsoNormal" style=" line-height: 150%; mso-list: l5 level4 lfo8; tab-stops: list .25in left 45.8pt 91.6pt 137.4pt 183.2pt 229.0pt 274.8pt 320.6pt 366.4pt 412.2pt 458.0pt 503.8pt 549.6pt 595.4pt 641.2pt 687.0pt 732.8pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->A record of each extension of credit in an amount in excess
                        of $10,000, except an extension of credit secured by an interest in real
                        property. The record shall contain the name and address of the person to whom
                        the extension of credit is made, the amount thereof, the nature or purpose
                        thereof and the date thereof;</p>
                <p class="MsoNormal" style=" line-height: 150%; mso-list: l5 level4 lfo8; tab-stops: list .25in left 45.8pt 91.6pt 137.4pt 183.2pt 229.0pt 274.8pt 320.6pt 366.4pt 412.2pt 458.0pt 503.8pt 549.6pt 595.4pt 641.2pt 687.0pt 732.8pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->A record of each advice, request or instruction received or
                        given regarding any transaction resulting (or intended to result and later
                        canceled if such a record is normally made) in the transfer of currency or other
                        monetary instruments, funds, checks, investment securities or credit, of more
                        than $10,000 to or from any person, account or place outside the U.S.;</p>
                <p class="MsoNormal" style=" line-height: 150%; mso-list: l5 level4 lfo8; tab-stops: list .25in left 45.8pt 91.6pt 137.4pt 183.2pt 229.0pt 274.8pt 320.6pt 366.4pt 412.2pt 458.0pt 503.8pt 549.6pt 595.4pt 641.2pt 687.0pt 732.8pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->A record of each advice, request or instruction given to
                        another financial institution (which includes broker-dealers) or other person
                        located within or without the U.S., regarding a transaction intended to result
                        in the transfer of funds, or of currency, other monetary instruments, checks,
                        investment securities or credit, of more than $10,000 to a person, account or
                        place outside the U.S.;</p>
                <p class="MsoNormal" style=" line-height: 150%; mso-list: l5 level4 lfo8; tab-stops: list .25in left 45.8pt 91.6pt 137.4pt 183.2pt 229.0pt 274.8pt 320.6pt 366.4pt 412.2pt 458.0pt 503.8pt 549.6pt 595.4pt 641.2pt 687.0pt 732.8pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->Each document granting signature or trading authority over
                        each customer's account;</p>
                <p class="MsoNormal" style=" line-height: 150%; mso-list: l5 level4 lfo8; tab-stops: list .25in left 45.8pt 91.6pt 137.4pt 183.2pt 229.0pt 274.8pt 320.6pt 366.4pt 412.2pt 458.0pt 503.8pt 549.6pt 595.4pt 641.2pt 687.0pt 732.8pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->Each record described in Exchange Act Rule 17a-3(a): (1)
                        (blotters), (2) (ledgers for assets and liabilities, income, and expense and
                        capital accounts), (3) (ledgers for cash and margin accounts), (4) (securities
                        log), (5) (ledgers for securities in transfer, dividends and interest received,
                        and securities borrowed and loaned), (6) (order tickets), (7) (purchase and sale
                        tickets), (8) (confirms), and (9) (identity of owners of cash and margin
                        accounts);</p>
                <p class="MsoNormal" style=" line-height: 150%; mso-list: l5 level4 lfo8; tab-stops: list .25in left 45.8pt 91.6pt 137.4pt 183.2pt 229.0pt 274.8pt 320.6pt 366.4pt 412.2pt 458.0pt 503.8pt 549.6pt 595.4pt 641.2pt 687.0pt 732.8pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->A record of each remittance or transfer of funds, or of
                        currency, checks, other monetary instruments, investment securities or credit,
                        of more than $10,000 to a person, account or place, outside the U.S.; and</p>
                <p class="MsoNormal" style=" line-height: 150%; mso-list: l5 level4 lfo8; tab-stops: list .25in left 45.8pt 91.6pt 137.4pt 183.2pt 229.0pt 274.8pt 320.6pt 366.4pt 412.2pt 458.0pt 503.8pt 549.6pt 595.4pt 641.2pt 687.0pt 732.8pt;">
                    <!-- [if !supportLists]-->
                    <span style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol;">
                        <span style="mso-list: Ignore;">·<span style="font: 7.0pt 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                    </span><!--[endif]-->A record of each receipt of currency, other monetary
                        instruments, checks or investment securities and of each transfer of funds or
                        credit, of more than $10,000 received on any one occasion directly and not
                        through a domestic financial institution, from any person, account or place
                        outside the U.S.</p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">14.<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>Clearing/Introducing Company Relationships</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%; mso-layout-grid-align: none; text-autospace: none;">
                    <span style="color: black;">We will work closely with our clearing company to detect money laundering.
                    </span>
                    <span style="mso-bidi-font-style: italic;">We will exchange information,
                        records, data and exception reports as necessary to comply [with our contractual
                        obligations and] with AML laws.
                    </span>
                    <span style="color: black;">Both our company and our clearing company have filed
                        (and kept updated) the necessary annual certifications for such information
                        sharing, which can be found on
                    </span>
                    <span class="MsoHyperlink">
                        <span style="color: black; mso-themecolor: text1; mso-bidi-font-style: italic; text-decoration: none; text-underline: none;">
                            <a href="https://www.fincen.gov/">
                                <span style="color: black; mso-themecolor: text1;">FinCEN’s</span>
                                <span style="color: black; mso-themecolor: text1;">website</span>
                            </a>
                        </span>
                    </span>
                    <span style="mso-bidi-font-style: italic;">. As a general matter, we will obtain
                        and use the following exception reports offered by our clearing company in order
                        to monitor customer activity
                    </span>
                    <span style="color: black;">and we will provide our clearing company with proper
                        customer identification and due diligence information as required to
                        successfully monitor customer transactions. We have
                    </span>discussed how each company will apportion customer and transaction
                    functions and how we will share information and set forth our understanding in a
                    written document. We understand that the apportionment of functions will not
                    relieve either of us from our independent obligation to comply with AML laws,
                    except as specifically allowed under the BSA and its implementing regulations<span style="mso-bidi-font-style: italic;">.</span>
                </p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">15.<span style="mso-tab-count: 1;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>Training Programs</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will develop ongoing employee
                        training under the leadership of the AML Compliance Person and senior
                        management. Our training will occur on at least an annual basis. It will be
                        based on our company’s size, its customer base, and its resources and be
                        updated
                    </span>as necessary to reflect any new developments in the law.</p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">Our training will include, at a
                        minimum: (1) how to identify red flags and signs of money laundering that arise
                        during the course of the employees’ duties; (2) what to do once the risk
                        is identified (including
                    </span>how, when and to whom to escalate unusual customer activity or other red
                    flags for analysis and, where appropriate, the filing of SARs)<span style="mso-bidi-font-style: italic;">;
                        (3) what employees' roles are in the company's compliance efforts and how to
                        perform them; (4) the company's record retention policy; and (5) the
                        disciplinary consequences (including civil and criminal penalties) for
                        non-compliance with the BSA.
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will develop training in our
                        company, or contract for it. Delivery of the training may include educational
                        pamphlets, videos, intranet systems, in-person lectures and explanatory memos.
                        We will maintain records to show the persons trained, the dates of training and
                        the subject matter of their training.
                    </span>
                </p>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will review our operations to see
                        if certain employees, such as those in compliance, margin and corporate
                        security, require specialized additional training. Our written procedures will
                        be updated to reflect any such changes.</span>
                    
                </p>

                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">16. Program to Independently Test AML Program</span>
                </h2>

                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; line-height: 150%;">a. Staffing</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">The testing
                    of our AML program will be performed at least annually (on a calendar year
                    basis) by Oladipo Olayanju, personnel of our company, who is not the AML
                    Compliance Person nor does he perform the AML functions being tested nor do they
                    report to any such persons. His<em style="mso-bidi-font-style: normal;"></em>qualificationsinclude a working knowledge of applicable requirements under the BSA and itsimplementing regulations. To ensure that he remains independent, we willseparate his functions from other AML activities. Independent testing will beperformed more frequently if circumstances warrant.</p>
                
                <h2 style="margin-left: 0in; line-height: 150%; mso-list: none; tab-stops: .5in;">
                    <span style="font-size: 12.0pt; mso-bidi-font-weight: normal;">b. Evaluation and Reporting</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">After we
                    have completed the independent testing, staff will report its findings to senior
                    management. We will promptly address each of the resulting recommendations and
                    keep a record of how each noted deficiency was resolved.<span style="mso-spacerun: yes;">&nbsp;
                    </span>
                </p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">17. Monitoring Employee Conduct and Accounts</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    <span style="mso-bidi-font-style: italic;">We will subject employee accounts to
                        the same AML procedures as customer accounts, under the supervision of the AML
                        Compliance Person. We will also review the AML performance of supervisors, as
                        part of their annual performance review.</span>
                    The AML Compliance Person’s accounts will be reviewed by Oladipo Olayanju<em>.</em>
                </p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">18. Confidential Reporting of AML Non-Compliance</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">Employees
                    will promptly report any potential violations of the company’s AML
                    compliance program to the AML Compliance Person, unless the violations implicate
                    the AML Compliance Person, in which case the employee shall report to
                    <span style="mso-bidi-font-style: italic;">president of the board</span>. Such
                    reports will be confidential, and the employee will suffer no retaliation for
                    making them.</p>
                <p class="MsoNormal" style="line-height: 150%;">
                    
                </p>
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">19. Additional Risk Areas</span>
                </h2>
                <h2 style="margin-bottom: .0001pt; line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%; font-weight: normal;">The
                        company has reviewed all areas of its business to identify potential money
                        laundering risks that may not be covered in the procedures described above. The
                        major additional areas of risk include; negative publicity, damage to corporate
                        reputation, legal and regulatory sanctions.</span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">
                    
                </p>
                
                <h2 style="line-height: 150%;">
                    <span style="font-size: 12.0pt; line-height: 150%;">20. Senior Manager Approval<span style="mso-spacerun: yes;">&nbsp;
                        </span>
                    </span>
                </h2>
                <p class="MsoNormal" style="line-height: 150%;">Senior
                    management has approved this AML compliance program in writing as reasonably
                    designed to achieve and monitor our company’s ongoing compliance with the
                    requirements of the BSA and the implementing regulations under it. This approval
                    is indicated by signatures below.</p>
                
                <p class="MsoNormal" style="line-height: 150%;">Signed: Oladipo Olayanju</p>
                
                <p class="MsoNormal" style="line-height: 150%;">Title: Founder/CEO</p>
                
                <p class="MsoNormal" style="line-height: 150%;">Date: 10/20/2022</p>
            </div>
            </div>
            <!-- /.col-lg -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </section>
  </div>
</template>

<script>

  import PageTopSection from '/src/components/sections/PageTopSection.vue'

  export default {
    name: 'Aml',
    metaInfo: function() {
        return {
            title: "Afridext Aml | Respectmart",
        }
    },
    data() {
        return {

        };
    },

    components: {
        PageTopSection
    },

  }
</script>
